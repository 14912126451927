<template>
    <split-pane  :min-percent='10' :default-percent='12' split="vertical">
      <template slot="paneL">       
        <el-input placeholder="请输入关键字进行过滤" v-model="filterText" size="medium"></el-input> 
        <el-tree :data="treeData" 
                  :props="defaultProps" 
                  @node-click="handleNodeClick" 
                  node-key="id"  
                  v-loading="loading"
                  element-loading-text="加载中"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(255, 251, 255, 0.8)"
                  :default-expanded-keys="['0']"                     
                  :style="{height: scrollerHeight,overflow:'auto',display: 'flex' }"
                  highlight-current
                  :filter-node-method="filterNode"
                  ref="eltree"
                  :expand-on-click-node="false"
        >
            <template v-slot="{node,data}">                                     
                <span v-if="data.id ==0 "  class="el-icon-notebook-1 nodeRoot" >
                  <span class="nodeLabel">{{ node.label}} </span>
                </span>                  
                <span v-else class="el-icon-folder" > 
                    <span class="nodeLabel">{{ node.label}} </span>
                </span>
            </template>
        </el-tree>        
      </template>
      <template slot="paneR">
        <div class="siderCls" >
          <el-button @click="addRec()" type="text" icon="el-icon-plus" style="margin-right:20px;"> 新增属性</el-button>                              
          <el-input :placeholder="input_hold" v-model="textparam" @keyup.enter.native="qryPropData" size="small" style="width:400px;margin-left:10px;vertical-align:center">
              <el-select v-model="selparam" slot="prepend" placeholder="请选择列名" clearable @change="elchange" style="width:140px;">
                  <span v-for="item in propCol" :key='item.key'>
                      <el-option :label="item.title" :value="item.key"></el-option>
                  </span>
              </el-select>
          </el-input> 
          <div> 
          <el-table  :data="propData" 
              border                            
              size="small"
              ref="proptab"
              height="300px" 
              highlight-current-row
              @row-click="propClick" >
              <span v-for="(item ,index) in propCol" :key="index">
                <el-table-column   v-if="item.key!=='ISHAND'"
                    :prop="item.key"
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    sortable
                    :min-width="item.width">
                </el-table-column>
                <el-table-column  v-if="item.key==='ISHAND'"
                    :prop="item.key"
                    :label="item.title"
                    align="center"                                  
                    :min-width="item.width">
                    <template slot-scope="scope" >
                        <el-checkbox disabled false-label="N" true-label="Y" v-model="scope.row.ISHAND" >&nbsp;</el-checkbox>
                    </template>
                </el-table-column>
              </span>
              <el-table-column
                  fixed="right"
                  label="操作"
                  align="center"
                  width="150">
                  <template slot-scope="scope">
                      <el-button size="small"   type="text" icon="el-icon-edit" @click="editRec(scope.row,scope.$index)">编辑</el-button>
                      <el-button size="small"   type="text" icon="el-icon-close" @click="delRec(scope.row,scope.$index)">删除</el-button>   
                  </template>
              </el-table-column>                         
          </el-table>
          </div>
        </div>
          <el-button @click="dtlAdd()" type="text" icon="el-icon-plus" style="margin-right:20px;"> 新增属性值</el-button>                               
          <el-input :placeholder="input_hold2" v-model="textparam2" @keyup.enter.native="qryDtlData" size="small" style="width:400px;margin-left:10px;vertical-align:center">
              <el-select v-model="selparam2" slot="prepend" placeholder="请选择列名" clearable @change="elchange2" style="width:140px;">
                  <span v-for="item in dtlCol" :key='item.key'>
                      <el-option :label="item.title" :value="item.key"></el-option>
                  </span>
              </el-select>
          </el-input> 
          <div> 
          <el-table  :data="dtlData" 
              border                            
              size="small"
              ref="dtltab"
              :height="subHeight" 
              highlight-current-row
              @row-click="dtlClick" >
              <el-table-column  v-for="(item ) in dtlCol" :key="item.key"
                  :prop="item.key"
                  :label="item.title"
                  show-overflow-tooltip
                  :align="item.align"                                  
                  sortable
                  :min-width="item.width">
              </el-table-column> 
              <el-table-column
                  fixed="right"
                  label="操作"
                  align="center"
                  width="150">
                  <template slot-scope="scope">
                      <el-button size="small"   type="text" icon="el-icon-edit" @click="dtlEdit(scope.row,scope.$index)">编辑</el-button>
                      <el-button size="small"   type="text" icon="el-icon-close" @click="dtlDel(scope.row,scope.$index)">删除</el-button>   
                  </template>
              </el-table-column>                        
          </el-table>
          </div>
          <!--添加属性对话框-->
          <el-dialog :visible.sync="addModel" v-dialogDrag width="50%" append-to-body  :close-on-press-escape="false" :close-on-click-modal="false">
              <div slot="title" :style="{'background':'#409eff','color':'white','height':'30px'}">
                  <span style="line-height:30px;height:30px;font-size:16px;padding-left:15px;">产品类别属性</span>
              </div>
            <Form ref="addForm" :model="propRow" :label-width="110"   label-position="right" inline  >  
              <span v-for="(item,index) in propForm" :key="index" >
                <FormItem  :label="item.FLDNM"  class="item-width" v-if="item.FHIDE==='N'" :prop="item.FLDNO">                  
                  <el-input type="textarea" :rows="item.ROWNUMS" v-if="item.EDCSS==='textarea'" v-model="propRow[item.FLDNO]"  ></el-input>   
                  <el-checkbox border v-else-if="item.EDCSS==='checkbox'" true-label="Y"  false-label="" v-model="propRow[item.FLDNO]"  style="100%"></el-checkbox>
                  <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false "  v-model="propRow[item.FLDNO]"></el-input>
                </FormItem>
              </span>
            </Form>
            <div style="height:40px;line-height:30px;border-top:0px solid #ccc;padding-top:4px; " >
              <span style="display:flex;justify-content: center;">
                  <el-button type="info" @click="addModel=false" size="small"  icon="iconfont icon-shanchu">  取消</el-button>
                  <el-button @click="addOk" size="small" type="primary" style="margin-left:40px;" icon="iconfont icon-baocun"  > 保存 </el-button>  
              </span>
            </div>
        </el-dialog>
        <!--新增产品属性的规格列-->
        <el-dialog v-dialogDrag :visible.sync="dtlModel" width="70%" append-to-body  :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#67c23a','color':'black','height':'30px'}">
                <span style="line-height:30px;height:30px;font-size:16px;padding-left:15px;">产品属性规格 （建议用AI写作产品特点）</span>
            </div>          
            <Form :model="dtlRow" :label-width="110"   label-position="right" inline :rules="ruleValidate" >  
              <span v-for="(item,index) in dtlForm" :key="index" >
                  <!--我在FormItem标签上添加了:style="{ height: item.FLDNO === 'MATTY' ? '333px' : '' }"属性，这将使得带有dtlRow['CNAME']和ENAME的二行的高度为333px。-->
                <FormItem  :label="item.FLDNM"  class="item-width" v-if="item.FHIDE==='N'" :prop="item.FLDNO" :style="{ height: item.FLDNO === 'CNAME' || item.FLDNO === 'ENAME' ? '119px' : '' }">                  
                  <el-input type="textarea" :rows="item.ROWNUMS" v-if="item.EDCSS==='textarea'" v-model="dtlRow[item.FLDNO]" style="" ></el-input>   
                  <el-select   @change="modelChange" v-else-if="item.FLDNO==='MATTY'" v-model="dtlRow[item.FLDNO]"  filterable style="width:100%"  >
                      <el-option v-for="(item,index) in modelArr" :key="index" :value="item.SUBNO" :label="item.CNAME" ></el-option>
                  </el-select>
                  <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false "  v-model="dtlRow[item.FLDNO]"></el-input>
                </FormItem>
              </span>
            </Form>
          <div style="height:40px;line-height:30px;display:flex;justify-content:center;border-top:0px solid #ccc;padding-top:4px; " >
            <span style="display:flex;justify-content:center;">
                <el-button type="info" @click="dtlModel=false" size="small"  icon="iconfont icon-shanchu">  取消</el-button>
                <el-button  @click="dtlOk" size="small" type="primary" style="margin-left:50px;" icon="iconfont icon-baocun"  > 保存 </el-button>  
            </span>
          </div>
        </el-dialog>
        <el-dialog v-dialogDrag :visible.sync="promptWin" width="450px" append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">提示</span>
            </div>
            <div   >{{prompt}} </div>
            <span slot="footer"  :style="{'height':'40px' }">
                <el-button size="small" type="primary" @click="promptWin = false">确 定</el-button>
            </span>
        </el-dialog>
    </template>
  </split-pane>
</template>

<script>
import { getTabColOrData,getBaseData } from '../../api/user' 
import { paramData } from '../../api/Select'
export default {
  name:"prd_proplib",
  data() {
    return {
      promptWin:false,
      prompt:'',
      loading:false,
      addModel:false,
      dtlModel:false,
      LANG:'',
      treeData:[] ,//  树数据
      propCol:[], //属性列（表格隐藏列）
      propForm:[], //属性表单列（表单隐藏列）
      propRow:{},
      dtlRow:{},
      dtlCol:[],  //规格列
      dtlForm:[],  //规格列
      selparam:'',// select 绑定值
      selparam2:'',// select 绑定值
      textparam:'', // input 绑定值
      textparam2:'', // input 绑定值
      defaultProps: 
      {  //el-tree 默认字段属性
          children: 'children',
          id:'id',
          icon:'icon',
          label: 'label'
      },
      modelArr:[],
      treeid :'0',
      level:'1',
      nodeLabel:'',
      filterText: '',
      propData:[] , //类别属性数据
      dtlData:[] , //类别属性规格数据
      v_url:this.$store.state.queryUrl , //api请求路径     
      v_likeurl:this.$store.state.baseUrl+'sysprivs/getLikeManyRec', //多记录查询 like模式    
      v_username:this.$cookies.get('v_username'), //api 请求用户
      v_password:this.$cookies.get('v_password') ,//api 请求密码
      input_hold:'请输入内容...',
      input_hold2:'请输入内容...',
      ruleValidate: {
          "MATTY":[{"required":true,"message":"所属不能为空","trigger":"change"}],
          "CNAME":[{"required":true,"message":"所属不能为空","trigger":"change"}],
          "ENAME":[{"required":true,"message":"所属不能为空","trigger":"change"}],
      },
    }
  },
  watch: {
    filterText(val) {
      this.$refs.eltree.filter(val);
    }
  },
  created() {
    this.LANG =this.$store.state.lang
    this.getData()
    this.getPropCol() //类别属性字段
    this.getDtlCol() //类别属性规格字段
  },
 
  computed: {
    scrollerHeight: function() {
        return (window.innerHeight - 140) + 'px';
    }, 
    subHeight: function() {
        return (window.innerHeight - 480) + 'px';
    }, 
  },
  methods: {
    modelChange(){
        this.$forceUpdate()
    },
    //属性条件过滤
    qryPropData(){
        var vm=this
        getTabColOrData(this.v_likeurl,this.v_username,this.v_password,'V_PRD_CATEPROP','Pcode',this.treeid,this.selparam,this.textparam,'','','').then((res) => {              
            vm.propData  =res.data.result
            if(res.data.result.length>0){
              vm.$refs.proptab.setCurrentRow(res.data.result[0],true) 
              vm.propRow=res.data.result[0]
              vm.getDtlData(res.data.result[0].IDSEQ ,res.data.result[0].LSTSEQ  );
            }else{
              vm.getDtlData('-1','-1' );
            }
        }); 
    },
    //属性规格条件过滤
    qryDtlData(){
        var vm=this
        if (this.propRow['IDSEQ'])
        getTabColOrData(this.v_likeurl,this.v_username,this.v_password,'V_PRD_PROPDTL','idseq',this.propRow.IDSEQ,'lstseq',this.propRow.LSTSEQ,this.selparam2,this.textparam2,'').then((res) => {              
            vm.dtlData  =res.data.result  
            if(res.data.result.length>0){
              vm.$refs.dtltab.setCurrentRow(res.data.result[0],true) 
              vm.dtlRow =res.data.result[0]
            }   
        });  
    },
    //类别属性操作
    addOk(){
      let vm =this
      if (this.propRow['CNAME'] && this.propRow['ENAME']){
        vm.$axios({
            method: 'post',
            url:vm.$store.state.baseUrl+'sysprivs/tableBodySave?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
            data: {p_json:JSON.stringify(this.propRow), p_table:'PRD_CATEPROP',p_idseq:this.propRow.IDSEQ},
        }).then(res=>{      
            if(res.data.result ==='OK')  {          
                vm.addModel =false   
                vm.getPropData(vm.treeid );
            }else{
                this.promptWin=true
                this.prompt = res.data.result 
            }        
        })  
      }else{
        this.promptWin=true
        this.prompt ='属性中文名和属性英文名不能为空！'
      }
    },
    addRec(){
      if (this.level ==='1'){
        this.promptWin=true
        this.prompt ='此节点不能新增属性'
      }else{
        this.addModel =true
        this.propRow={IDSEQ:this.treeid,PCNAME:this.nodeLabel,LSTSEQ:''}
      }

    },
    editRec(row){ 
       this.addModel =true
    },
    delRec(row){
        this.$Modal.confirm({
          title: '删除确认',
          content: '<p>你确定要删除此行记录吗</p>',
          onOk: () => {
              //发起删除请求
              var vm=this;
              this.$axios({
                  method: 'post',
                  url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                  data: {p_key:'idseq',p_value:row.IDSEQ,p_key2:'lstseq',p_value2:row.LSTSEQ,p_table:'PRD_CATEPROP'},
              }).then(res=>{      
                  if(res.data.code =='200')  {    
                      vm.getPropData(vm.treeid );                           
                  }else{
                      this.promptWin=true
                      this.prompt =res.data.message
                  }        
              }) 
          }
      });
    },
    //类别属性规格操作
    dtlOk(){
      let vm =this
      if (this.dtlRow['MATTY'] && this.dtlRow['CNAME'] && this.dtlRow['ENAME']){
        vm.$axios({
            method: 'post',
            url:vm.$store.state.baseUrl+'sysprivs/tableBodySave2?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
            data: {p_json:JSON.stringify(this.dtlRow), p_table:'PRD_PROPDTL' },
        }).then(res=>{      
            if(res.data.result ==='OK')  {          
                vm.dtlModel =false   
                vm.getDtlData(vm.propRow['IDSEQ'],vm.propRow['LSTSEQ'] );
            }else{
                this.promptWin=true
                this.prompt = res.data.result
            }        
        })  
      }
      else{
        this.promptWin=true
        this.prompt ='规格中文名，规格英文名，和所属系列不能为空'
      }
    },
    dtlAdd(){
      if (this.level ==='1'){
        this.promptWin=true
        this.prompt ='此节点不能新增规格'
      }else{
        if (!this.propRow['IDSEQ']){
            this.promptWin=true
            this.prompt ='属性记录不能为空'
        }else{
          this.dtlModel =true
          this.dtlRow ={IDSEQ:this.propRow['IDSEQ'],LSTSEQ:this.propRow['LSTSEQ'],ITMSEQ:'',PCNAME:this.propRow['CNAME'] }
        }
      }
    },
    dtlEdit(){
      this.dtlModel =true
    },
    dtlDel(row){
        this.$Modal.confirm({
          title: '删除确认',
          content: '<p>你确定要删除此行记录吗</p>',
          onOk: () => {
              //发起删除请求
              var vm=this;
              let idseq_=row.IDSEQ
              let lstseq_=row.LSTSEQ
              this.$axios({
                  method: 'post',
                  url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                  data: {p_key:'idseq',p_value:idseq_,p_key2:'lstseq',p_value2:lstseq_,p_key3:'itmseq',p_value3:row.ITMSEQ,p_table:'PRD_PROPDTL'},
              }).then(res=>{      
                  if(res.data.code =='200')  {    
                      vm.getDtlData(idseq_, lstseq_ );                    
                  }else{
                      this.promptWin=true
                      this.prompt = res.data.message
                  }        
              }) 
          }
      });
    },
    elchange(val){  // 这个val 就是 el-select 的 value
        let obj ={}
        obj =this.propCol.find(item=>{
            return item.key===val
        })
        if (obj)
        this.input_hold='请输入'+obj.title+' 的值'
    },
    elchange2(val){  // 这个val 就是 el-select 的 value
        let obj ={}
        obj =this.dtlCol.find(item=>{
            return item.key===val
        })
        if (obj)
        this.input_hold2='请输入'+obj.title+' 的值'
    },
    //获取类别属性数据
    getPropData(v_code){
        var vm=this
        getTabColOrData(this.v_url,this.v_username,this.v_password,'V_PRD_CATEPROP','Pcode',v_code,'','','','','').then((res) => {              
            vm.propData  =res.data
            if(res.data.length>0){
              vm.$refs.proptab.setCurrentRow(res.data[0],true) 
              vm.propRow=res.data[0]
              vm.getDtlData(res.data[0].IDSEQ ,res.data[0].LSTSEQ  );
              this.$nextTick(() => { //有固定列时 查询后重新布局
                    this.$refs.proptab.doLayout();
                }) 
            }else{
              vm.getDtlData('-1','-1' );
            }
        });            
    },
    propClick(row){
      this.propRow =JSON.parse(JSON.stringify(row))  //深拷贝
      this.getDtlData(row.IDSEQ,row.LSTSEQ)
    },
    //获取类别属性--规格 数据
    getDtlData(v_idseq,v_lstseq){
        var vm=this
        getTabColOrData(this.v_url,this.v_username,this.v_password,'V_PRD_PROPDTL','idseq',v_idseq,'lstseq',v_lstseq,'','','').then((res) => {              
            vm.dtlData  =res.data  
            if(res.data.length>0){
              vm.$refs.dtltab.setCurrentRow(res.data[0],true) 
              vm.dtlRow =res.data[0]
            }
            this.$nextTick(() => { //有固定列时 查询后重新布局
                this.$refs.dtltab.doLayout();
            })    
        });            
    },
    dtlClick(row){
       this.dtlRow =JSON.parse(JSON.stringify(row))  //深拷贝
    },
    // 获取 树形控件数据
    getData() {  
        var vm=this;
        vm.loading=true
        this.$axios({
            method: 'post',
            url:vm.$store.state.baseUrl+'sysprivs/getElTreeData?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
            data: {p_table:vm.LANG==='English'?'V_PRD_CATEGORY_EN':'V_PRD_CATEGORY'}
        }).then(function(res){                  
            vm.treeData =res.data;   
            if(res.data.length > 0 ){
                //'nextTick()' 下次dom更新时触发回调函数
                vm.$nextTick().then(() =>{
                    //使用setCurrentKey方法的时候必须将其放在nextTick中,要等数据更新渲染完再调用,否则找不到对应元素
                    vm.$refs.eltree.setCurrentKey(vm.treeid)
                })
            }     
            vm.loading =false          
        })                              
    },
    //节点点击
    handleNodeClick(data){
        this.nodeLabel=data.label;            
        this.treeid =data.id
        this.getPropData(data.id );
        this.level =data.icon
        paramData('parentno',data.id,'PRD_CATEGORY').then((res)=>{               
            this.modelArr =res.data.result
        })
    },

    //节点过滤
    filterNode(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
    },
    //获取属性表字段
    getPropCol(){
        var vm=this
        getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','17','fty','HEAD','','',' order by sortby,lstseq' ).then((res) => {
            vm.propForm =res.data
            for (let k=0; k<res.data.length; k++){
                if (res.data[k].THIDE==='N') { // 
                    vm.propCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,   
                        disabled:res.data[k].DISABLED,                   
                        fhide:res.data[k].FHIDE,
                    })
                }
            }
        });
    },
    //获取规格表字段  v-enter-number
    getDtlCol(){
        var vm=this
        getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','17','fty','HEAD2','','',' order by sortby,lstseq' ).then((res) => {
            vm.dtlForm =res.data
            for (let k=0; k<res.data.length; k++){
                if (res.data[k].THIDE==='N') { // 
                    vm.dtlCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,   
                        disabled:res.data[k].DISABLED,                   
                        fhide:res.data[k].FHIDE,
                    })
                }
            }
        });
    },
  }
}
</script>
<style lang="scss" scoped>
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
</style> 
<style lang="less">
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
 
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb;
        
    }
    .item-width  {  //弹窗的对话class CSS
    width: 48%; //原来是32px;
    height:36px;  //原来是：25px;
} 

</style>